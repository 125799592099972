<template>
	<div class="uc-box uc-main-box">
		<div class="uc-content-box order-list-box">
			<!-- 头部 -->
			<div class="box-hd">
				<h1 class="title">{{cData.Title}}</h1>
				<div class="clearfix"></div>
			</div>
			<!-- 头部END -->

			<div class="box-bd">
				<div class="userInfo">
					<div v-html="cData.Contents"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'HelpInfo',
		data() {
			return {
				classid: '',
				cData: {
					Title: '',
					Contents: ''
				}
			};
		},
		activated() {
		},
		created() {
			this.classid = this.$route.query.id
			console.log('this.classid',this.classid)
			this.loadbangzhuList()
		},
		methods: {
			async getinfo() {
				var res = await this.postdata('/api/News/ClassInfo', {
					queryId: this.classid
				})
				if (res.code == 200) {
					this.cData = res.data
				}
			},
			async loadbangzhuList() {
				var res = await this.postdata("/api/News/HelpCenterDetail", {

					"queryId": this.classid
				});
				if (res.code == 200) {
					this.cData = res.data.Info;
				}
			},
		}

	}
</script>
<style scoped>
	.userInfo .Personalbtn {
		margin-left: 18%;
		margin-top: 16px;
	}
	
	.clearfix {
		clear: both;
	}
	
	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}
	
	.box {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}
	
	.userInfo .text {
		width: 150px;
		font-size: 15px;
		text-align: right;
	}
	
	.userInfo .rt {}
	
	.userInfo .rt .input {
		border: 1px solid #bbb;
		font-size: 15px;
		line-height: 30px;
		padding: 0 6px;
		border-radius: 4px;
	}
	
	
	
	.box-bd {
		margin-top: 16px;
	}
	
	.uc-box .order-empty {
		margin: 0 auto;
	}
	
	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		color: #b0b0b0;
		overflow: hidden;
	}
	
	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}
	
	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}
	
	.uc-box {
		background: #fff
	}
	
	.uc-box .uc-content-box {
		margin: 0 48px
	}
	
	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}
	
	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}
	
	.uc-box .uc-content-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 16px;
		line-height: 1.25
	}
	
	.uc-box .uc-content-box .box-hd .filter-list a {
		color: #757575;
		cursor: pointer
	}
	
	.uc-box .uc-content-box .box-hd .filter-list li {
		float: left;
		padding: 0 20px;
		border-left: 1px solid #e0e0e0;
		color: #757575
	}
	
	.uc-box .uc-content-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}
	
	.uc-box .uc-content-box .box-hd .filter-list li.active,
	.uc-box .uc-content-box .box-hd .filter-list li.active a,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active a {
		color: #ff6700
	}
	
	.hide {
		display: none !important
	}
	
	.uc-order-item {
		position: relative
	}
	
	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}
	
	.btn:hover {
		text-decoration: none;
		color: #b0b0b0
	}
	
	.btn:focus {
		outline: 0
	}
	
	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}
	
	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}
	
	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}
	
	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}
	
	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}
	
	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}
	
	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}
	
	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}
	
	.btn-primary {
		background: #ff6700;
		border-color: #ff6700;
		color: #fff;
	}
	
	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}
	
	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}
	
	.ordernum {
		color: #757575;
	}
	
	
</style>
<style>
	td,th{
		    padding: 5px 10px;
		    border: 1px solid #DDD;
	}
</style>
